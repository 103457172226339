import axios, { AxiosResponse } from "axios";
import { MapData } from "../types/MapData";

export const tracksApi = {
    create: async (mapData: MapData) => {
        return await axios.post<any, AxiosResponse<MapData>>('/tracks', mapData);
    },
    get: async (id: string) => {
        return await axios.get<any, AxiosResponse<{ data: MapData, id: number }>>(`/tracks/${id}`);
    },
    update: async (id: string, data: MapData) => {
        return await axios.put(`/tracks/${id}`, { data });
    },
    delete: async (id: string) => {
        return await axios.delete(`/tracks/${id}`);
    },
    list: async (params: {}) => {
        return await axios.get<any, AxiosResponse<MapData[]>>('/tracks');
    }

} 