import { MixedRateValue } from "../types/rates";
import { convertRange } from "./range";

const minRc = 1000;
const midRc = 1500;
const maxRc = 2000;

export const constrain = (value: number, min: number, max: number): number => {
  return Math.max(min, Math.min(value, max));
};

export const rcCommand = (
  rcData: number,
  rcRate: number,
  deadband: number = 0
) => {
  const tmp = Math.min(Math.max(Math.abs(rcData - midRc) - deadband, 0), 500);

  let result = tmp * rcRate;

  if (rcData < midRc) {
    result = -result;
  }

  return result;
};
export const getRaceflightRates = (
  input: number,
  config: MixedRateValue
): number => {
  const rcRate = config.rfRate || 0;
  const rate = config.rfAcro || 0;
  const expo = config.rfExpo || 0;

  let angularVel = (1 + 0.01 * expo * (input * input - 1.0)) * input;
  angularVel = angularVel * (rcRate + Math.abs(angularVel) * rcRate * rate * 0.01);
  return angularVel;
};

export const getKISSRates = (input: number, config: MixedRateValue): number => {
  const abs = Math.abs(input);
  const rate = config.kissRate || 0;
  const rcCurve = config.kissRcCurve || 0;
  const rcRate = config.kissRcRate || 0;

  const kissRpy = 1 - abs * rate;
  const kissTempCurve = input * input;

  input =
    (input * kissTempCurve * rcCurve + input * (1 - rcCurve)) * (rcRate / 10);
  return 2000 * (1.0 / kissRpy) * input;
};

export const getActualRates = (
  input: number,
  config: MixedRateValue
): number => {
  let angularVel;
  const maxRate = config.acMaxRate || 0;
  const center = config.acCenterSensitivity || 0;
  const expo = config.acExpo || 0;
  const abs = Math.abs(input);

  const expof = abs * (Math.pow(input, 5) * expo + input * (1 - expo));
  angularVel = Math.max(0, maxRate - center);
  angularVel = input * center + angularVel * expof;
  return angularVel;
};

export const getQuickRates = (
  input: number,
  config: MixedRateValue
): number => {
  const abs = Math.abs(input);
  const expo = config.qrExpo || 0;
  let rate = config.qrMaxRate || 0;
  let rcRate = config.qrRcRate || 0;

  rcRate = rcRate * 200;
  rate = Math.max(rate, rcRate);

  let angularVel;
  const superExpoConfig = (rate / rcRate - 1) / (rate / rcRate);
  const curve = Math.pow(abs, 3) * expo + abs * (1 - expo);

  angularVel = 1.0 / (1.0 - curve * superExpoConfig);
  angularVel = input * rcRate * angularVel;
  return angularVel;
};

export const getBetaflightRates = (
  input: number,
  config: MixedRateValue,
  limit: number
): number => {
  let angularVel;
  const abs = Math.abs(input);
  let rcRate = config.bfRcRate || 0;
  const rcExpo = config.bfRcExpo || 0;
  const rate = config.bfRate || 0;

  if (rcRate > 2) {
    rcRate = rcRate + (rcRate - 2) * 14.54;
  }

  let expoPower;
  let rcRateConstant;

  expoPower = 3;
  rcRateConstant = 200;

  if (rcExpo > 0) {
    input = input * Math.pow(abs, expoPower) * rcExpo + input * (1 - rcExpo);
  }

  var rcFactor = 1 / constrain(1 - abs * rate, 0.01, 1);
  angularVel = rcRateConstant * rcRate * input; // 200 should be variable checked on version (older versions it's 205,9)
  angularVel = angularVel * rcFactor;

  angularVel = constrain(angularVel, -1 * limit, limit); // Rate limit from profile

  return angularVel;
};
export type RatesType = "raceflight" | "kiss" | "actual" | "quickrates" | "betaflight";

export const rateInputToDegreesPerSecond = (
  type: RatesType,
  rcData: number,
  config: MixedRateValue
): number => {
  let rcCommandf = rcCommand(rcData, 1);
  rcCommandf = rcCommandf / 500;

  switch (type) {
    case "raceflight":
      return getRaceflightRates(rcCommandf, config);
    case "kiss":
      return getKISSRates(rcCommandf, config);
    case "actual":
      return getActualRates(rcCommandf, config);
    case "quickrates":
      return getQuickRates(rcCommandf, config);
    default:
      return getBetaflightRates(rcCommandf, config, 1998);
  }
};
export const inputToDegres = (
  type: RatesType,
  input: number,
  config: MixedRateValue
) => {
  return rateInputToDegreesPerSecond(
    type,
    convertRange(input, -1, 1, 1000, 2000),
    config
  );
};
export const drawRateCurve = (
  type: RatesType,
  config: any,
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  controllerValues: { yaw: number; roll: number; pitch: number }
) => {
  context.clearRect(0, 0, width, height);
  context.fillStyle = "rgba(0, 0, 0, 0.4)";
  context.fillRect(0, 0, width, height);
  context.strokeStyle = "#000000";
  context.lineWidth = 0.5;

  // Horizontal
  context.beginPath();
  context.moveTo(0, height / 2);
  context.lineTo(width, height / 2);
  context.stroke();

  // Vertical
  context.beginPath();
  context.moveTo(width / 2, 0);
  context.lineTo(width / 2, height);
  context.stroke();
  const yawMax = rateInputToDegreesPerSecond(
    type,
    maxRc,
    config["yaw"] as MixedRateValue
  );
  const rollMax = rateInputToDegreesPerSecond(
    type,
    maxRc,
    config["roll"] as MixedRateValue
  );
  const pitchMax = rateInputToDegreesPerSecond(
    type,
    maxRc,
    config["pitch"] as MixedRateValue
  );
  const cv = controllerValues;
  let maxAngularVel = Math.max(yawMax, rollMax, pitchMax);
  context.strokeStyle = "black";
  context.font = "12px Courier New";
  context.fillStyle = "red";
  const inputRoll = rateInputToDegreesPerSecond(
    type,
    convertRange(cv.roll, -1, 1, minRc, maxRc),
    config["roll"]
  );

  context.fillText(
    `${Math.round(rollMax)}/${Math.round(inputRoll)} roll`.padEnd(10, " "),
    5,
    20
  );

  context.fillStyle = "green";
  const inputPitch = Math.round(
    rateInputToDegreesPerSecond(
      type,
      convertRange(cv.pitch, -1, 1, minRc, maxRc),
      config["pitch"]
    )
  );

  context.fillText(
    `${Math.round(pitchMax)}/${inputPitch} pitch`.padEnd(10, " "),
    5,
    35
  );
  const inputYaw = Math.round(
    rateInputToDegreesPerSecond(
      type,
      convertRange(cv.yaw, -1, 1, minRc, maxRc),
      config["yaw"]
    )
  );
  context.fillStyle = "blue";
  context.fillText(
    `${Math.round(yawMax)}/${inputYaw} yaw`.padEnd(10, " "),
    5,
    50
  );

  drawAxeRateCurve(
    type,
    config["yaw"] as MixedRateValue,
    context,
    width,
    height,
    "blue",
    maxAngularVel,
    controllerValues.yaw
  );
  drawAxeRateCurve(
    type,
    config["pitch"] as MixedRateValue,
    context,
    width,
    height,
    "green",
    maxAngularVel,
    controllerValues.pitch
  );
  drawAxeRateCurve(
    type,
    config["roll"] as MixedRateValue,
    context,
    width,
    height,
    "red",
    maxAngularVel,
    controllerValues.roll
  );
};

export const drawAxeRateCurve = (
  type: RatesType,
  config: MixedRateValue,
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  color: string,
  maxAngularVel: number,
  value: number
) => {
  context.strokeStyle = color;

  context.lineWidth = 1;
  const stepWidth = 1;

  const canvasHeightScale = height / (2 * maxAngularVel);

  context.save();
  context.translate(width / 2, height / 2);

  context.beginPath();
  let input = minRc;
  context.moveTo(
    -width / 2,
    -canvasHeightScale * rateInputToDegreesPerSecond(type, input, config)
  );
  input = input + stepWidth;

  while (input <= maxRc) {
    context.lineTo(
      convertRange(input - midRc, -500, 500, -width / 2, width / 2),
      -canvasHeightScale * rateInputToDegreesPerSecond(type, input, config)
    );

    input = input + stepWidth;
  }
  const dotX = convertRange(value, -1, 1, -width / 2, width / 2);
  const dotY =
    -canvasHeightScale *
    rateInputToDegreesPerSecond(
      type,
      convertRange(value, -1, 1, 1000, 2000),
      config
    );
  context.fillStyle = color;
  context.fillRect(dotX - 2, dotY - 2, 4, 4);
  context.stroke();
  context.restore();
};
