import { Scene, Vector3 } from "@babylonjs/core";
import { gameState } from "../../state/GameState";
import { MapData } from "../../types/MapData";
import { DroneData } from "../../types/drone";
import { drones } from "../data/drones";
import { Drone } from "../logic/Drone";
import { EnviromentFactory } from "../scene/enviroments/EnviromentFactory";
import { Obstacle } from "../scene/obstacles/Obstacle";
import { ObstacleFactory } from "../scene/obstacles/ObstacleFactory";
import { AssetsLoader } from "./AssetsLoader";
import { IPlugin } from "./IPlugin";
export class TrackLoaderPlugin implements IPlugin {
  private obstacleFactory: ObstacleFactory;
  private assetsLoader: AssetsLoader;
  private environmentFactory: EnviromentFactory;

  constructor(private scene: Scene) {
    this.obstacleFactory = new ObstacleFactory(scene);
    this.assetsLoader = new AssetsLoader(scene);
    this.environmentFactory = new EnviromentFactory(scene);

  }
  load() {


    console.log("TrackLoader.load");
    gameState.on("track:init", this.initTrack.bind(this));
    gameState.on("drone:init", this.initDrone.bind(this));
  }
  dispose() {
    console.log("TrackLoader.unload");
    gameState.off("track:init", this.initTrack.bind(this));
  }

  async initTrack(track: MapData) {
    this.scene.getNodes().forEach(node => node.dispose());

    await this.assetsLoader.loadAll();

    this.loadObstacles(this.scene, track);
    console.log("Track data", track)
    const env = this.scene.metadata["enviroment"] = this.environmentFactory.create(track.enviroment, {});
    env.init();

    // await this.loadScene(this.scene, track);
    // this.loadGround(this.scene, track);
    // this.loadSky(this.scene, track.sky);
    // this.loadLights(this.scene);



    gameState.emit("scene:loaded");
    // const droneData = drones[0];
    // if (droneData) {
    //   const drone = new Drone(this.scene, drones[0]);
    //   gameState.emit("drone:loaded", drone);
    //   console.log("drone loaded", drone);
    // }
  }

  initDrone(drone: DroneData) {
    const droneData = drones[0];
    if (droneData) {
      const drone = new Drone(this.scene, drones[0]);
      gameState.emit("drone:loaded", drone);
      console.log("drone loaded", drone);
    }
  }
  async loadScene(scene: Scene, mapData: MapData) {
    return new Promise((resolve, reject) => {

      // SceneLoader.Append(config.assetsUrl + "/3d/scenes/", "Stadium.glb", scene, () => {
      // SceneLoader.Append(config.assetsUrl + "/3d/scenes/", "WarehouseVeryBig.glb", scene, (scene: Scene) => {
      // SceneLoader.Append(config.assetsUrl + "/3d/scenes/factory2/", "Factory1Items.gltf", scene, () => {
      //   // scene.getNodeByName("__root__").getChildMeshes().forEach(mesh => {
      //   //   const parent = mesh.parent;
      //   //   mesh.parent = null;
      //   //   mesh.physicsImpostor = new PhysicsImpostor(mesh, PhysicsImpostor.MeshImpostor, { mass: 0, restitution: 0.1, damping:1,  }, scene);
      //   //   mesh.parent = parent;
      //   //   mesh.checkCollisions = false;
      //   // }); 
      //   // console.log(scene.lights);
      //   for (let i = scene.lights.length - 1; i >= 0; i--) {
      //     let light = scene.lights[i];
      //     scene.removeLight(light);
      //   }
      //   //scene.getTransformNodeByName("__root__").position = new Vector3(0, 1, 0);


      //   resolve(true);
      // });
    });
  }


  loadObstacles(scene: Scene, mapData: MapData) {
    this.obstacleFactory = new ObstacleFactory(scene);
    mapData.obstacles.forEach((obstacleData, index) => {
      console.log("obstacleData", obstacleData);
      const obstacle = this.obstacleFactory.create(obstacleData.name, obstacleData);
      obstacle.id = obstacleData.id;
      obstacle.position = new Vector3(obstacleData.position.x, obstacleData.position.y, obstacleData.position.z);
      obstacle.rotation = new Vector3(obstacleData.rotation.x, obstacleData.rotation.y, obstacleData.rotation.z);

      if (obstacle && obstacle instanceof Obstacle) {

        obstacle.coliders.forEach(collider => {
          collider.isPickable = true;
        });
        obstacle.order = obstacleData.order;

        // obstacle.isFirstGate = obstacleData.isFirstGate;
        // obstacle.isFinishGate = obstacleData.isFinishGate;
      }
    });
  }



}