import { useEffect, useRef, useState } from "react";
import { Button, FormControl, FormSelect } from "react-bootstrap";
import * as uuid from "uuid";
import { Controller } from "../../../engine/gamepad/Controller";
import { ratesState } from "../../../state/RatesState";
import {
  betaflightDefault,
  defaultRates,
  suggestedRates,
} from "../../../types/defaultRates";
import {
  RateProfileType,
  RateType,
  rateTypes,
  RateValueKeys,
} from "../../../types/rates";
import { drawRateCurve, inputToDegres } from "../../../util/rates";
import "./Rates.scss";

type AxesType = "roll" | "pitch" | "yaw";

export const Rates = () => {
  const axes: AxesType[] = ["roll", "pitch", "yaw"];
  const [addingNewProfile, setAddingNewProfile] = useState(false);
  const [newProfileName, setNewProfileName] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(ratesState.profile);
  const [copyFromId, setCopyFromId] = useState("");
  const [userProfiles, setUserProfiles] = useState(ratesState.profiles);
  const [controllerValues, setControllerValues] = useState({
    yaw: 0,
    roll: 0,
    pitch: 0,
  });

  const chartRef = useRef(null);

  const selectedRateType = rateTypes.find(
    (r) => r.key === selectedProfile?.type
  );
  const updateRateValue = (
    ax: AxesType,
    prop: RateValueKeys,
    value: number
  ) => {
    if (!selectedProfile) return;
    selectedProfile.values[ax][prop] = value;
    ratesState.updateRateProfile({ ...selectedProfile });
  };
  useEffect(() => {
    ratesState.on("profile:selected", setSelectedProfile);
    ratesState.on("profile:updated", setSelectedProfile);
    ratesState.on("profile:added", setSelectedProfile);
    ratesState.on("profiles:changed", setUserProfiles);
  });
  useEffect(() => {
    const controller = new Controller();
    const intervalID = setInterval(() => {
      const values = controller.getTRYPValues();
      if (!values) return;

      setControllerValues({
        roll: values.roll,
        pitch: values.pitch,
        yaw: values.yaw,
      });
    }, 100);
    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    const canvas = chartRef?.current;
    if (canvas) {
      const context = (canvas as HTMLCanvasElement).getContext("2d");
      const el = canvas as HTMLCanvasElement;
      if (context && selectedProfile) {
        drawRateCurve(
          selectedProfile.type,
          selectedProfile.values,
          context,
          el.width,
          el.height,
          controllerValues
        );
      }
    }
  }, [selectedProfile, chartRef, controllerValues]);

  const updateProfileType = (value: RateType) => {
    if (!selectedProfile) return;

    const rateProfile = {
      ...defaultRates[value],
      id: selectedProfile.id,
      name: selectedProfile.name,
    };

    ratesState.updateRateProfile(rateProfile);
  };

  const saveNewProfile = () => {
    const profileTemplate =
      copyFromId !== ""
        ? [...ratesState.profiles, ...suggestedRates].find(
            (p) => p.id === copyFromId
          )!
        : betaflightDefault;
    const profile: RateProfileType = {
      ...profileTemplate,
      name: newProfileName,
      id: uuid.v7(),
    };
    ratesState.addRateProfile(profile);
    ratesState.selectRateProfile(profile);

    setAddingNewProfile(false);
    setNewProfileName("");
    setCopyFromId("");
  };
  const onProfileChange = (id: string) => {
    const profile = ratesState.profiles.find((p) => p.id === id);
    if (!profile) return;
    ratesState.selectRateProfile(profile);
  };

  return (
    <div className="rates menu-panel">
      <table style={{ maxWidth: "450px" }}>
        <tr>
          <th style={{ width: "100px" }}></th>
          <th style={{ width: "100px" }}></th>
          <th style={{ width: "60px" }}></th>
          <th style={{ width: "70px" }}></th>
        </tr>
        {addingNewProfile && (
          <tr>
            <th>Profile name</th>
            <th>
              <input
                type="text"
                className="form-control form-control-sm"
                style={{ width: "100%" }}
                value={newProfileName}
                placeholder="Enter profile name"
                onChange={(e) => setNewProfileName(e.currentTarget.value)}
              />
            </th>
            <th>
              <Button
                size="sm"
                disabled={newProfileName === "" ? true : false}
                onClick={saveNewProfile}
              >
                <i className="bi bi-check" /> Save
              </Button>
            </th>
            <th>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setAddingNewProfile(false)}
              >
                <i className="bi bi-x" /> Cancel
              </Button>
            </th>
          </tr>
        )}
        {!addingNewProfile && (
          <tr>
            <th>Profile</th>
            <th>
              <FormSelect
                size="sm"
                value={selectedProfile.id}
                style={{ width: "100%" }}
                onChange={(e) => onProfileChange(e.currentTarget.value)}
              >
                {ratesState.profiles.map((profile, index) => (
                  <option key={index} value={profile.id}>
                    {profile.name}
                  </option>
                ))}
              </FormSelect>
            </th>
            <th>
              <Button size="sm" onClick={() => setAddingNewProfile(true)}>
                <i className="bi bi-plus" /> New
              </Button>
            </th>
            <th>
              <Button
                size="sm"
                variant="danger"
                onClick={() => ratesState.removeRateProfile(selectedProfile)}
              >
                <i className="bi bi-trash" />
              </Button>
            </th>
          </tr>
        )}
        {addingNewProfile && (
          <tr>
            <th>Copy from</th>
            <th>
              <FormSelect
                size="sm"
                value={copyFromId}
                style={{ width: "100%" }}
                onChange={(e) => setCopyFromId(e.currentTarget.value)}
              >
                <option>Choose rate profile</option>
                <optgroup label="Default profiles">
                  {suggestedRates.map((rate) => (
                    <option key={rate.id} value={rate.id}>
                      {rate.name}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="User profiles">
                  {userProfiles.map((rate) => (
                    <option key={rate.id} value={rate.id}>
                      {rate.name}
                    </option>
                  ))}
                </optgroup>
              </FormSelect>
            </th>
            <th></th>
            <th></th>
          </tr>
        )}
        {!addingNewProfile && (
          <tr>
            <th>Rate type</th>
            <th>
              <FormSelect
                size="sm"
                value={selectedProfile?.type || undefined}
                style={{ width: "100%" }}
                onChange={(e) =>
                  updateProfileType(e.currentTarget.value as RateType)
                }
              >
                {rateTypes.map((rateType) => (
                  <option key={rateType.key} value={rateType.key}>
                    {rateType.name}
                  </option>
                ))}
              </FormSelect>
            </th>
            <th></th>
            <th></th>
          </tr>
        )}
      </table>

      <div className="row mt-2">
        <table>
          <tbody>
            <tr>
              <th>Rates</th>
              {selectedRateType?.props.map((rateType, index) => (
                <th className="text-center max-col" key={index}>
                  {rateType.name}
                </th>
              ))}
              <th style={{ width: "50px" }}> deg/s</th>
            </tr>
            {axes.map((ax, axIndex) => (
              <tr key={axIndex}>
                <td>{ax.toLocaleUpperCase()}</td>
                {selectedRateType?.props.map((rateType, index) => (
                  <td key={index}>
                    <FormControl
                      className="prop-input"
                      type="number"
                      disabled={addingNewProfile}
                      step={rateType.step || 0.01}
                      min={rateType.min || 0}
                      max={rateType.max || undefined}
                      onChange={(e) => {
                        let value = Number(e.currentTarget.value);
                        if (value < (rateType?.min ?? 0))
                          value = rateType?.min ?? 0;
                        if (value > (rateType?.max ?? 0))
                          value = rateType?.max ?? 0;

                        updateRateValue(ax, rateType.key, value);
                      }}
                      value={selectedProfile.values[ax][rateType.key] || 0}
                    />
                  </td>
                ))}
                <td>
                  {Math.round(
                    inputToDegres(
                      selectedProfile.type,
                      1,
                      selectedProfile.values[ax]
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        Rates
        <canvas
          ref={chartRef}
          style={{ width: "100%", height: "140px" }}
        ></canvas>
      </div>
    </div>
  );
};
