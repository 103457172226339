import {
    CreateBox,
    Mesh,
    Scene,
    Vector3
} from "@babylonjs/core";
import { Obstacle } from "../Obstacle";
import { getGatesSizes } from "./GatesConstants";

const mgpLadderName = (isbig: boolean, size: number) => {
    return `MGPCube${size}x${isbig ? '7x6' : '5x5'}`;
};
export class MGPCube extends Obstacle {
    private meshes: Mesh[] = [];
    constructor(name: string, scene: Scene, isBig: boolean = false, size: number = 3) {
        super(mgpLadderName(isBig, size), scene);
        const { innerWidth, innerHeight, depth } = getGatesSizes(isBig);
        const totalHeight = innerHeight * size + size * depth;

        // need to generate if size is 1 cube if 2 then 2 staced cubes and so on
        // should create for each 4 sides cube in total height and to place them on each corner. cube shoudl be
        // deptxdepthxheight I need to generate front-left, front right, back left, back right leg
        // front left
        const frontLeft = CreateBox("frontLeft", { width: depth, height: totalHeight, depth }, scene);
        const frontRight = CreateBox("frontRight", { width: depth, height: totalHeight, depth }, scene);
        const backLeft = CreateBox("backLeft", { width: depth, height: totalHeight, depth }, scene);
        const backRight = CreateBox("backRight", { width: depth, height: totalHeight, depth }, scene);
        frontLeft.position = new Vector3(-innerWidth / 2, totalHeight / 2, innerWidth / 2);
        frontRight.position = new Vector3(innerWidth / 2, totalHeight / 2, innerWidth / 2);
        backLeft.position = new Vector3(-innerWidth / 2, totalHeight / 2, -innerWidth / 2);
        backRight.position = new Vector3(innerWidth / 2, totalHeight / 2, -innerWidth / 2);
        frontLeft.setParent(this);
        frontRight.setParent(this);
        backLeft.setParent(this);
        backRight.setParent(this);

        // now I need to connect each end of the cube with box that will be deptxdepth from end to end if side boxes

        const frontTop = CreateBox("frontTop", { width: innerWidth + depth, height: depth, depth }, scene);
        const frontBottom = CreateBox("frontBottom", { width: innerWidth + depth, height: depth, depth }, scene);
        const backTop = CreateBox("backTop", { width: innerWidth + depth, height: depth, depth }, scene);
        const backBottom = CreateBox("backBottom", { width: innerWidth + depth, height: depth, depth }, scene);
        const leftTop = CreateBox("leftTop", { width: depth, height: depth, depth: innerWidth }, scene);
        const leftBottom = CreateBox("leftBottom", { width: depth, height: depth, depth: innerWidth }, scene);
        const rightTop = CreateBox("rightTop", { width: depth, height: depth, depth: innerWidth }, scene);
        const rightBottom = CreateBox("rightBottom", { width: depth, height: depth, depth: innerWidth }, scene);

        frontTop.position = new Vector3(0, totalHeight + depth / 2, innerWidth / 2);
        frontBottom.position = new Vector3(0, 0, innerWidth / 2);
        backTop.position = new Vector3(0, totalHeight + depth / 2, -innerWidth / 2);
        backBottom.position = new Vector3(0, 0, -innerWidth / 2);
        leftTop.position = new Vector3(-innerHeight / 2, totalHeight + depth / 2, 0);
        leftBottom.position = new Vector3(-innerHeight / 2, 0, 0);
        rightTop.position = new Vector3(innerHeight / 2, totalHeight + depth / 2, 0);
        rightBottom.position = new Vector3(innerHeight / 2, 0, 0);

        frontTop.setParent(this);
        frontBottom.setParent(this);
        backTop.setParent(this);
        backBottom.setParent(this);
        leftTop.setParent(this);
        leftBottom.setParent(this);
        rightTop.setParent(this);
        rightBottom.setParent(this);

        for (let i = 1; i < size; i++) {
            console.log("side", i)
            const front = CreateBox("front" + i, { width: innerWidth, height: depth, depth }, scene);
            const back = CreateBox("back" + i, { width: innerWidth, height: depth, depth }, scene);
            const left = CreateBox("left" + i, { width: depth, height: depth, depth: innerWidth }, scene);
            const right = CreateBox("right" + i, { width: depth, height: depth, depth: innerWidth }, scene);

            front.position = new Vector3(0, innerHeight * i + i * depth + depth / 2, innerWidth / 2);
            back.position = new Vector3(0, innerHeight * i + i * depth + depth / 2, -innerWidth / 2);
            left.position = new Vector3(-innerWidth / 2, innerHeight * i + i * depth + depth / 2, 0);
            right.position = new Vector3(innerWidth / 2, innerHeight * i + i * depth + depth / 2, 0);

            front.setParent(this);
            back.setParent(this);
            left.setParent(this);
            right.setParent(this);
        }



        // this._scene.onBeforeRenderObservable.add(() => {
        //     this.rotation.y += 0.01;
        // });
        // for (let i = 0; i < size; i++) {
        //     const topless = i === size - 1 && hideTop;
        //     const gate = new MGPGate("gate" + i, scene, isBig, topless);
        //     gate.position = new Vector3(0, height * i, 0);
        //     gate.setParent(this);

        //     this.coliders.push(...gate.coliders);
        //     this.checkpoints.push(...gate.checkpoints);
        //     if (i < size - 1) {
        //         const helperCheckpoint = new Checkpoint("helper" + i, { shape: "square", depth, height: sideHeight, width: innerWidth, isHelper: true }, scene);
        //         helperCheckpoint.position = new Vector3(innerWidth + sidePanelSize, height * i + height / 2, 0);
        //         helperCheckpoint.rotation.y = Math.PI;
        //         helperCheckpoint.setParent(this);
        //     }

        // }
    }
}
