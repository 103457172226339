import { Color3, HemisphericLight, MeshBuilder, PhysicsImpostor, Vector3 } from "@babylonjs/core";
import { GridMaterial, SkyMaterial } from "@babylonjs/materials";
import { AbstractEnviroment } from "./AbstractEnviroment";

export class EmptyDayEnviroment extends AbstractEnviroment {
    get name() {
        return "empty-day";
    }
    init() {
        // this.scene.fogMode = Scene.FOGMODE_EXP;
        // this.scene.fogColor = new Color3(0.9, 0.9, 0.85);
        // this.scene.fogDensity = 0.001;

        // //Only if LINEAR
        // this.scene.fogStart = 1000.0;
        // this.scene.fogEnd = 2048.0;

        this.loadGround();
        this.loadSky();
        this.loadLights();
    }
    loadSky() {

        const skyBox = MeshBuilder.CreateBox("skyBox", { size: 2048, updatable: false }, this.scene);
        const material = this.getDefaultSkyboxMaterial();
        // skyBox.material =  materialUtil.getSkyboxMaterial(name || "default");
        skyBox.material = material;
        skyBox.position.y = 1000;


    }
    loadLights() {
        const light = new HemisphericLight("light", new Vector3(0, 1, 0), this.scene);
    }
    loadGround() {

        // const { width, height } = this.calculateGroundSize();
        const width = 1000;
        const height = 1000;
        const ground = MeshBuilder.CreateGround("ground", { height, width, updatable: false }, this.scene);

        var grid = new GridMaterial("grid", this.scene);
        grid.gridRatio = 1;
        grid.majorUnitFrequency = 2;
        grid.minorUnitVisibility = 0;
        grid.mainColor = new Color3(0.3, 0.3, 0.3);
        grid.lineColor = new Color3(0.5, 0.5, 0.5);

        ground.material = grid;

        ground.physicsImpostor = new PhysicsImpostor(
            ground,
            PhysicsImpostor.PlaneImpostor,
            { mass: 0, friction: 0.4, restitution: 0.7, damping: 100 },
            this.scene
        );
        ground.checkCollisions = true;
        console.log("ground:loaded")
        const disc = MeshBuilder.CreateDisc("disc", { radius: 1000, tessellation: 0, updatable: false }, this.scene);
        disc.rotation.x = Math.PI / 2;
        disc.position.y = -0.5;
        disc.material = grid;

    }
    private getDefaultSkyboxMaterial() {
        var skyMaterial = new SkyMaterial("DefaultSkyMaterial", this.scene);
        skyMaterial.backFaceCulling = false;
        skyMaterial.inclination = 0;
        return skyMaterial;
    }
}