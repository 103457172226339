import { EventEmitter } from "@foxify/events";
import { RenderEngine } from "../engine/RenderEngine";
import { Drone } from "../engine/logic/Drone";
import { Checkpoint } from "../engine/scene/obstacles/Checkpoint";
import { MapData } from "../types/MapData";
import { DroneData } from "../types/drone";

export type EngineMode = "game" | "editor" | "player";

type GameStateEvents = {
    "start": () => void
    "pause": () => void
    "loading": () => void,
    "loaded": () => void,
    "take:screenshot": () => void,
    "track:init": (trackData: MapData) => void
    "engine:stop": () => void
    "engine:start": (mode: EngineMode) => void
    "drone:init": (drone: DroneData) => void
    "drone:loaded": (drone: Drone) => void,
    "ui:fullscreen:toggle": () => void,
    "scene:loaded": () => void,
    "checkpoint:passed": (checkpoint: Checkpoint) => void,
};

type GameStateTypes = "loading" | "loaded" | "running" | "pause";

export class GameState extends EventEmitter<GameStateEvents> {
    _state: GameStateTypes = "pause";
    engine = new RenderEngine();

    public start() {
        this._state = "running";
        this.emit("start");
    }
    public pause() {
        this._state = "pause";
        this.emit("pause");
    }
    public get state() {
        return this._state;
    }
    public load() {
        this._state = "loading";
        this.emit("loading");
    }
    public loaded() {
        this._state = "loaded";
        this.emit("loaded");
    }
    public async engineStart(mode: EngineMode) {
        await this.engine.start(mode);
    }
    public engineStop() {
        this.emit("engine:stop");
    }
}

export const gameState = new GameState();