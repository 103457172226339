import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RequireAuth } from "../components/auth/RequireAuth";
import { GameMenu } from "../components/game-menu/GameMenu";
import { PlayTrack } from "../ui/play/PlayTrack";
import { AboutPage } from "./AboutPage";
import { AuthPage } from "./auth/AuthPage";
import { GlobalHistory } from "./global-history";
import { TrackEditor } from "./track-editor/TrackEditor";

// const Router = process.env.USE_HASH ? HashRouter : BrowserRouter
// const Router = BrowserRouter

export const AppRoutes = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <GlobalHistory />
        <Routes>
          <Route path="/" element={<GameMenu />} />
          <Route path="fly" element={<GameMenu />} />
          <Route path="profile" element={<GameMenu />} />
          <Route path="rates" element={<GameMenu />} />
          <Route path="controller" element={<GameMenu />} />
          <Route path="login" element={<AuthPage />} />
          <Route path="login/:requestId" element={<AuthPage />} />
          <Route path="play/:trackId" element={<PlayTrack />} />
          {/* <Route path="play"  element={<RequireAuth><PlayMenu/></RequireAuth>}/> */}
          {/* <Route path="play/:trackId" element={<RequireAuth><PlayTrack/></RequireAuth>}/> */}
          <Route path="tracks" element={<GameMenu />} />
          <Route
            path="tracks/new"
            element={
              <RequireAuth>
                <TrackEditor />
              </RequireAuth>
            }
          />
          <Route
            path="tracks/:id/edit"
            element={
              <RequireAuth>
                <TrackEditor />
              </RequireAuth>
            }
          />
          <Route path="about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
