import { EventEmitter } from "@foxify/events";
import { GameNode } from "../engine/scene/GameNode";
import { NodeItem } from "../engine/scene/obstacles/ObstacleFactory";
import { Box } from "../engine/scene/obstacles/misc/Box";
import { MapData } from "../types/MapData";


type TrackEditorStateEvents = {
    "track:changed": () => void,
    "track:init": (track: MapData) => void,
    "track:init:new": () => void,
    "track:name:changed": (name: string) => void,
    "node:added": (node: GameNode) => void,
    "node:selected": (node: GameNode) => void,
    "node:unselected": () => void,
    "ui:node:click": (name: string, data: any) => void,
    "ui:node:dragstart": (nodeItem: NodeItem) => void,
    "ui:node:focus": (id: string) => void,
    "ui:node:remove": (id: string) => void,
    "editor:init": () => void
};

export class TrackEditorState extends EventEmitter<TrackEditorStateEvents> {
    private nodes: GameNode[] = [];
    private trackData: MapData = {
        name: "New Track",
        enviroment: "empty",
        obstacles: []
    }

    getObstacles() {
        return this.nodes;
    }
    setTrackData(track: MapData) {
        this.trackData = track;
        this.nodes = [];
    }
    setNodes(nodes: GameNode[]) {
        this.nodes = nodes;
    }
    addObstacle(node: GameNode) {
        node.id = node.id || this.getUniqID();
        this.nodes.push(node);
        const position = node.getAbsolutePosition();
        this.trackData.obstacles.push({
            name: node.name,
            id: node.id,
            order: this.trackData.obstacles.length,
            position: {
                x: position.x,
                y: position.y,
                z: position.z
            },
            rotation: {
                x: node.rotation.x,
                y: node.rotation.y,
                z: node.rotation.z
            },

        });
        this.emit("node:added", node);
        this.emit("track:changed");
    }
    getMapData() {
        return this.trackData;
    }
    updatePositionAndRotations() {
        this.trackData.obstacles.forEach(dataObstacle => {
            const obstacle = this.findObstacle(dataObstacle.id);
            if (!obstacle) return;
            const position = obstacle.getAbsolutePosition();
            dataObstacle.position = {
                x: position.x,
                y: position.y,
                z: position.z
            };
            dataObstacle.rotation = {
                x: obstacle.rotation.x,
                y: obstacle.rotation.y,
                z: obstacle.rotation.z
            };
            if (obstacle instanceof Box) {
                dataObstacle.scaling = {
                    x: obstacle.scaling.x,
                    y: obstacle.scaling.y,
                    z: obstacle.scaling.z
                }
            }
        });
    }
    calculateOrder() {
        this.trackData.obstacles
            .sort((a, b) => a.order - b.order)
            .forEach((obstacle, index) => {
                obstacle.order = index;
            });
    }
    findObstacle(id: string) {
        return this.nodes.find(obstacle => obstacle.id === id);
    }
    removeObstacle(id: string) {
        const obstacle = this.nodes.find(obstacle => obstacle.id === id);
        if (!obstacle) return;

        const index = this.nodes.indexOf(obstacle);
        if (index > -1) {
            this.nodes.splice(index, 1);
            this.trackData.obstacles.splice(index, 1);
            obstacle.dispose();
            this.emit("track:changed");
        }
    }
    moveDown(id: string) {
        const index = this.nodes.findIndex(obstacle => obstacle.id === id);

        // Check if it's valid and not the last item
        if (index === -1 || index >= this.nodes.length - 1) return;

        // Create a new array with the obstacle moved down

        const newObstacles = [...this.nodes];
        [newObstacles[index], newObstacles[index + 1]] = [newObstacles[index + 1], newObstacles[index]];

        // Replace the old array with the new one
        this.nodes = newObstacles;

        // Emit the event to notify changes
        this.emit("track:changed");
    }
    moveUp(id: string) {
        // Find the obstacle
        const index = this.nodes.findIndex(obstacle => obstacle.id === id);

        // Check if it's valid and not the first item
        if (index <= 0) return;

        // Create a new array with the obstacle moved up
        const newObstacles = [...this.nodes];
        [newObstacles[index], newObstacles[index - 1]] = [newObstacles[index - 1], newObstacles[index]];

        // Replace the old array with the new one
        this.nodes = newObstacles;

        // Emit the event to notify changes
        this.emit("track:changed");
    }
    getUniqID() {
        return Math.random().toString(36).substr(2, 9);
    }
    setMapName(name: string) {
        this.trackData.name = name;
        this.emit("track:name:changed", name);
    }

}

export const trackEditorState = new TrackEditorState();