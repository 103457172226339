import { Animation, ArcRotateCamera, EventState, GizmoCoordinatesMode, GizmoManager, GlowLayer, Matrix, Nullable, PickingInfo, PointerEventTypes, PointerInfo, Scene, TransformNode, Vector2, Vector3 } from "@babylonjs/core";
import { trackEditorState } from "../../state/TrackEditorState";
import { MapData } from "../../types/MapData";
import { EnviromentFactory } from "../scene/enviroments/EnviromentFactory";
import { GameNode } from "../scene/GameNode";
import { Obstacle } from "../scene/obstacles/Obstacle";
import { NodeItem, ObstacleFactory } from "../scene/obstacles/ObstacleFactory";
import { AssetsLoader } from "./AssetsLoader";
import { IPlugin } from "./IPlugin";



export class TrackEditorPlugin implements IPlugin {
    private obstacleFactory: ObstacleFactory;
    private gizmoManager: GizmoManager;
    private attachedNode?: TransformNode;
    private snapToGround: boolean = true;
    private snapToGroundOffset: number = 0.1;
    private canvas: HTMLCanvasElement;
    private dragItem?: NodeItem;
    private dragNode?: GameNode;
    private assetsLoader: AssetsLoader;
    private environmentFactory: EnviromentFactory;



    constructor(private scene: Scene) {
        this.canvas = scene.getEngine().getRenderingCanvas() as HTMLCanvasElement;
        this.obstacleFactory = new ObstacleFactory(scene);
        this.assetsLoader = new AssetsLoader(scene);
        this.environmentFactory = new EnviromentFactory(scene);

        this.gizmoManager = new GizmoManager(this.scene);
        scene.onPointerObservable.add(this.pointerHandler.bind(this));
        this.gizmoManager.positionGizmoEnabled = true;
        this.gizmoManager.rotationGizmoEnabled = true;
        this.gizmoManager.scaleGizmoEnabled = true;


        this.gizmoManager.attachableMeshes = [];
        this.gizmoManager.attachableNodes = [];
        if (this.gizmoManager.gizmos.positionGizmo) {
            this.gizmoManager.gizmos.positionGizmo.coordinatesMode = GizmoCoordinatesMode.Local
            this.gizmoManager.gizmos.positionGizmo.scaleRatio = 1.8;
            this.gizmoManager.gizmos.positionGizmo.onDragEndObservable.add(() => {
                trackEditorState.updatePositionAndRotations();
                trackEditorState.emit("track:changed");
                this.fixSnapToGround();
            });
        }
        if (this.gizmoManager.gizmos.scaleGizmo) {
            this.gizmoManager.gizmos.scaleGizmo.scaleRatio = 1.3;
            this.gizmoManager.gizmos.scaleGizmo.sensitivity = 10;
            this.gizmoManager.gizmos.scaleGizmo.snapDistance = 1;
            this.gizmoManager.gizmos.scaleGizmo.onDragEndObservable.add(() => {
                trackEditorState.updatePositionAndRotations();
                trackEditorState.emit("track:changed");
            });
        }
        if (this.gizmoManager.gizmos.rotationGizmo) {
            this.gizmoManager.gizmos.rotationGizmo.coordinatesMode = GizmoCoordinatesMode.Local
            this.gizmoManager.gizmos.rotationGizmo.updateGizmoRotationToMatchAttachedMesh = false;
            this.gizmoManager.gizmos.rotationGizmo.onDragEndObservable.add(() => {
                trackEditorState.updatePositionAndRotations();
                trackEditorState.emit("track:changed");
            });
        }

        if (!this.canvas) {
            throw new Error("Canvas not found");
        } else {
            this.canvas.addEventListener("dragover", this.positionDragEvent.bind(this));
            this.canvas.addEventListener("dragenter", this.handleDragEnter.bind(this));
            this.canvas.addEventListener("dragleave", this.handleDragLeave.bind(this));
            this.canvas.addEventListener("drop", this.handleDrop.bind(this));
        }

    }
    load(): void {
        trackEditorState.on("ui:node:click", this.onObstacleUiClick.bind(this));
        trackEditorState.on("ui:node:remove", this.onObstacleUiRemove.bind(this));
        trackEditorState.on("ui:node:focus", this.onObstacleUiFocus.bind(this));
        trackEditorState.on("editor:init", this.initMapEditor.bind(this));
        trackEditorState.on("track:init", this.initTrack.bind(this));
        trackEditorState.on("track:init:new", () => {
            this.initTrack({ name: 'New Track', enviroment: "empty-day", obstacles: [] });
        });
        trackEditorState.on("ui:node:dragstart", (o) => {
            this.dragItem = o;
        })
        const glowLayer = new GlowLayer("glow", this.scene);
        glowLayer.intensity = 1.2; // Adjust intensity as needed

    }
    dispose(): void {
        this.scene.onPointerObservable.removeCallback(this.pointerHandler);
        trackEditorState.off("ui:node:click", this.onObstacleUiClick);
        trackEditorState.off("ui:node:remove", this.onObstacleUiRemove);
        trackEditorState.off("ui:node:focus", this.onObstacleUiFocus);
        trackEditorState.off("editor:init", this.initMapEditor);
        trackEditorState.off("ui:node:dragstart", (o) => {
            this.dragItem = o;
        });
        this.gizmoManager.dispose();

    }
    async initTrack(track: MapData) {
        this.scene.getNodes().forEach(node => node.dispose());
        trackEditorState.setTrackData(track);

        await this.assetsLoader.loadAll();
        const nodes: GameNode[] = [];
        track.obstacles.forEach((obstacleData, index) => {
            const obstacle = this.obstacleFactory.create(obstacleData.name, obstacleData);
            obstacle.id = obstacleData.id;
            obstacle.position = new Vector3(obstacleData.position.x, obstacleData.position.y, obstacleData.position.z);
            obstacle.rotation = new Vector3(obstacleData.rotation.x, obstacleData.rotation.y, obstacleData.rotation.z);

            if (obstacle && obstacle instanceof Obstacle) {

                obstacle.coliders.forEach(collider => {
                    collider.isPickable = true;
                });
                obstacle.order = obstacleData.order;

                // obstacle.isFirstGate = obstacleData.isFirstGate;
                // obstacle.isFinishGate = obstacleData.isFinishGate;
            }
            nodes.push(obstacle);
        });
        trackEditorState.setNodes(nodes);
        console.log("Track data", track)
        const env = this.scene.metadata["enviroment"] = this.environmentFactory.create(track.enviroment, {});
        env.init();

    }

    async initMapEditor() {
        const camera = new ArcRotateCamera("camera", -Math.PI / 4, Math.PI / 2.6, 27, Vector3.Zero(), this.scene);
        camera.attachControl(this.scene.getEngine().getRenderingCanvas(), true);
        camera.zoomToMouseLocation = true;
        camera.lowerRadiusLimit = 1;
    }
    fixSnapToGround(): void {
        if (!this.attachedNode) return;
        if (!this.snapToGround) return;
        if (Math.abs(this.attachedNode.position.y) < this.snapToGroundOffset) {
            this.attachedNode.position.y = 0;
        }
    }
    pointerHandler(pointerInfo: PointerInfo, eventState: EventState) {

        if (!this.scene) {
            return;
        }

        if (pointerInfo.type === PointerEventTypes.POINTERDOWN && pointerInfo.pickInfo?.pickedMesh) {
            this.gizmoManager.attachToMesh(null);

            const pickedMesh = pointerInfo.pickInfo.pickedMesh;

            if (["ground", "skyBox"].indexOf(pickedMesh.name) !== -1) return;

            const node = this.findLastParent(pickedMesh);
            if (node instanceof GameNode) {
                this.attachedNode = node;
                trackEditorState.emit("node:selected", node);
                this.gizmoManager.attachToNode(node);
                if (node.allowScaling()) {
                    this.gizmoManager.scaleGizmoEnabled = true;
                } else {
                    this.gizmoManager.scaleGizmoEnabled = false;
                }

            } else {
                this.gizmoManager.scaleGizmoEnabled = false;
                trackEditorState.emit("node:unselected");
            }

        }
    }
    findLastParent(node: TransformNode): TransformNode {
        if (!node.parent) return node;
        return this.findLastParent(node.parent as TransformNode);
    }
    onObstacleUiClick(name: string, data: any) {
        const obstacle = this.obstacleFactory.create(name, data);
        //need to place on center of screen to the ground
        obstacle.position = new Vector3(0, 0, 0);

        let pickInfo: Nullable<PickingInfo>;
        const scene = this.scene;
        const centerOfScreen = new Vector2(this.canvas.width / 2, this.canvas.height / 2);

        const ray = scene.createPickingRay(centerOfScreen.x, centerOfScreen.y, Matrix.Identity(), scene.activeCamera);

        // 3. Intersect the Ray with the Ground Plane
        pickInfo = scene.pickWithRay(ray, (mesh) => {
            return mesh.name === "ground"; // replace "ground" with the name or ID of your ground mesh
        });

        if (pickInfo && pickInfo.hit) {
            obstacle.position = pickInfo.pickedPoint!;
        }

        trackEditorState.addObstacle(obstacle);
    }
    onObstacleUiRemove(id: string) {
        trackEditorState.removeObstacle(id);
    }
    onObstacleUiFocus(id: string) {
        const obstacle = trackEditorState.findObstacle(id);
        if (!obstacle) return;

        const camera = this.scene?.activeCamera;
        if (camera instanceof ArcRotateCamera) {
            const target = obstacle.position.clone();

            const frames = 10;

            camera.animations = [];

            const targetAnim = new Animation("targetAnimation", "target", 30, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
            targetAnim.setKeys([{ frame: 0, value: camera.target }, { frame: frames, value: target }]);

            const radiusAnim = new Animation("radiusAnimation", "radius", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
            radiusAnim.setKeys([{ frame: 0, value: camera.radius }, { frame: frames, value: 20 }]);


            camera.animations.push(targetAnim);
            camera.animations.push(radiusAnim);
            this.scene?.beginAnimation(camera, 0, frames, false);

        }
    }
    loadGame() {


    }
    handleDragEnter(event: DragEvent): void {
        event.preventDefault();
        if (!this.dragNode && this.dragItem) {
            this.dragNode = this.obstacleFactory.create(this.dragItem.name, this.dragItem.data);
        }
        this.positionDragEvent(event);
    }
    handleDragLeave(event: DragEvent): void {
        event.preventDefault();
        if (this.dragNode) this.dragNode.dispose();
        this.dragNode = undefined;
    }
    handleDrop(event: DragEvent): void {
        event.preventDefault();
        if (this.dragNode) {
            trackEditorState.addObstacle(this.dragNode);
            this.dragNode = undefined;
            this.dragItem = undefined;
        }
    }


    private positionDragEvent(event: DragEvent) {
        event.preventDefault();
        var x = event.clientX;
        var y = event.clientY;

        const pickResult = this.scene.pick(x, y, (mesh) => {
            return mesh.name == "ground";
        });

        if (pickResult.pickedPoint && this.dragNode) {
            this.dragNode.position = pickResult.pickedPoint.clone();
            trackEditorState.emit("track:changed");
        }
    }
}