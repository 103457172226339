import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { CameraFovType, gameSettings } from "../../state/GameSettings";
export const CameraConfig = () => {
  const [cameraAngle, setCameraAngle] = useState<number>(
    gameSettings.cameraAngle
  );
  const [cameraFov, setCameraFov] = useState(gameSettings.cameraFov);
  const [aspectRatio, setAspectRatio] = useState<number>(
    gameSettings.screenAspectRation
  );
  const [cameraFovType, setCameraFovType] = useState<CameraFovType>(
    gameSettings.cameraFovType
  );
  const [cameraMixAngle, setCameraMixAngle] = useState<number>(
    gameSettings.cameraMixAngle
  );

  const onSettingsChanged = () => {
    setCameraAngle(gameSettings.cameraAngle);
    setCameraFov(gameSettings.cameraFov);
    setCameraMixAngle(gameSettings.cameraMixAngle);
    setCameraFovType(gameSettings.cameraFovType);
    setAspectRatio(gameSettings.screenAspectRation);
  };

  const onCameraAngleChanged = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return;
    }
    gameSettings.cameraAngle = value;
  };
  const onCameraFovChanged = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return;
    }
    gameSettings.cameraFov = value;
  };
  const onCameraMixAngleChanged = (value: number | number[]) => {
    if (Array.isArray(value)) {
      return;
    }
    gameSettings.cameraMixAngle = value;
  };
  const onCameraFovTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    gameSettings.cameraFovType = e.target.value as CameraFovType;
  };
  const onAspectRatioChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    gameSettings.screenAspectRation = Number(e.target.value);
  };
  useEffect(() => {
    gameSettings.on("settings:changed", onSettingsChanged);
    return () => {
      gameSettings.off("settings:changed", onSettingsChanged);
    };
  }, []);
  return (
    <Container>
      <Row>
        <label className="col-6 col-md-3">Camera angle</label>
        <div className="col-5 col-md-7">
          <Slider
            min={0}
            max={80}
            value={cameraAngle}
            onChange={onCameraAngleChanged}
          />
        </div>
        <div className="col-1 col-md-2">{cameraAngle}</div>
      </Row>
      <Row>
        <label className="col-6 col-md-3">Aspect ratio</label>
        <Col xs={5} md={7}>
          <Form.Check
            inline
            checked={aspectRatio === 0}
            onChange={onAspectRatioChanged}
            type="radio"
            value={0}
            name="aspectRatio"
            id="aspectRatio-0"
            label="Fit to screen"
          />

          <Form.Check
            inline
            type="radio"
            value={16 / 9}
            checked={aspectRatio === 16 / 9}
            onChange={onAspectRatioChanged}
            name="aspectRatio"
            id="aspectRatio-16:9"
            label="16:9"
          />
          <Form.Check
            inline
            type="radio"
            value={4 / 3}
            checked={aspectRatio === 4 / 3}
            onChange={onAspectRatioChanged}
            name="aspectRatio"
            id="aspectRatio-4:3"
            label="4:3"
          />
        </Col>
      </Row>
      <Row>
        <label className="col-6 col-md-3">Camera Fow type</label>
        <Col xs={5} md={7}>
          <Form.Check
            inline
            checked={cameraFovType === "diagonal"}
            onChange={onCameraFovTypeChanged}
            type="radio"
            value={"diagonal"}
            name="cameraFovType"
            id="cameraFovType-diagonal"
            label="Diagonal"
          />

          <Form.Check
            inline
            type="radio"
            value={"vertical"}
            checked={cameraFovType === "vertical"}
            onChange={onCameraFovTypeChanged}
            name="cameraFovType"
            id="cameraFovType-vertical"
            label="Vertical"
          />
          <Form.Check
            inline
            type="radio"
            value={"horizontal"}
            checked={cameraFovType === "horizontal"}
            onChange={onCameraFovTypeChanged}
            name="cameraFovType"
            id="cameraFovType-horizontal"
            label="Horizontal"
          />
        </Col>
      </Row>
      <div className="row">
        <label className="col-6 col-md-3">Camera fow</label>
        <div className="col-5 col-md-7">
          <Slider
            value={cameraFov}
            min={80}
            max={160}
            onChange={onCameraFovChanged}
          />
        </div>
        <div className="col-1 col-md-2">{cameraFov}</div>
      </div>
      <div className="row">
        <label className="col-6 col-md-3">Camera mix angle</label>
        <div className="col-5 col-md-7">
          <Slider
            min={0}
            max={70}
            value={cameraMixAngle}
            onChange={onCameraMixAngleChanged}
          />
        </div>
        <div className="col-1 col-md-2">{cameraMixAngle}</div>
      </div>
    </Container>
  );
};
