import { Scene } from "@babylonjs/core";
import { AbstractEnviroment } from "./AbstractEnviroment";
import { EmptyDayEnviroment } from "./EmptyDayEnviroment";
import { EmptyEnviroment } from "./EmptyEnviroment";


export class EnviromentFactory {
    constructor(private scene: Scene) { }

    create(name: string, data: any): AbstractEnviroment {
        switch (name) {
            case "empty": return new EmptyEnviroment(this.scene);
            case "empty-day": return new EmptyDayEnviroment(this.scene);


            default:
                throw new Error("Unknown obstacle type: " + data.type);
        }
    }

}
