import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { tracksApi } from "../../api/tracks";
import { gameState } from "../../state/GameState";
import { trackEditorState } from "../../state/TrackEditorState";
import { MapData } from "../../types/MapData";
import { Checkpoints } from "./Checkpoints";
import { Dropables } from "./Dropables";
import { MapDetails } from "./MapDetails";
import "./TrackEditor.scss";

export const TrackEditor = () => {
  const { id } = useParams();
  const [mapData, setMapData] = useState<MapData>();

  const [checkpointsExpanded, setCheckpointsExpanded] = useState(true);
  const [obstaclesExpanded, setObstaclesExpanded] = useState(true);

  // useEffect(() => {
  //   gameState.engineStart("editor");
  //   gameState.emit("track:init:new");
  //   trackEditorState.emit("editor:init");
  //   return () => {
  //     gameState.emit("engine:stop");
  //   };
  // }, []);

  useEffect(() => {
    gameState.engineStart("editor");
    if (id) {
      // trackEditorState.emit("track:load", id);
      tracksApi.get(id).then((response) => {
        console.log("response", response.data);
        setMapData(response.data.data);
        trackEditorState.emit("track:init", response.data.data);
        trackEditorState.emit("editor:init");
      });
    } else {
      trackEditorState.emit("track:init:new");
      trackEditorState.emit("editor:init");
    }
    return () => {
      gameState.emit("engine:stop");
    };
  }, [id]);
  return (
    <div className="track-editor">
      <MapDetails />
      {!checkpointsExpanded && (
        <button
          style={{
            position: "fixed",
            top: "16px",
            left: "8px",
            zIndex: 1000,
          }}
          className="transparent-button"
          onClick={() => setCheckpointsExpanded(true)}
        >
          Track nodes
        </button>
      )}
      {!obstaclesExpanded && (
        <button
          style={{
            position: "fixed",
            top: "16px",
            right: "8px",
            zIndex: 1000,
          }}
          className="transparent-button"
          onClick={() => setObstaclesExpanded(true)}
        >
          Track items
        </button>
      )}
      {checkpointsExpanded && (
        <div className="offcanvas left">
          <div
            className="offcanvas-title"
            onClick={() => setCheckpointsExpanded(false)}
          >
            Added items
          </div>
          <div className="offcanvas-content">
            <Checkpoints />
          </div>
        </div>
      )}
      {obstaclesExpanded && (
        <div className="offcanvas right">
          <div
            className="offcanvas-title"
            onClick={() => setObstaclesExpanded(false)}
          >
            Track items
          </div>
          <div className="offcanvas-content">
            <Dropables />
          </div>
        </div>
      )}
    </div>
  );
};
