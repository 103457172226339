import { EngineMode } from "../../state/GameState";
import { AdsLoaderPlugin } from "./AdsLoader";
import { CheckpointsPathPlugin } from "./CheckpointsPathPlugin";
import { CollisionPlugin } from "./CollisionPlugin";
import { DroneUpdatePlugin } from "./DroneUpdatePlugin";
import { GameplayPlugin } from "./GameplayPlugin";
import { ScreenshotPlugin } from "./ScreenshotPlugin";
import { SimulatorPlugin } from "./SimulatorPlugin";
import { SpeedOSDPlugin } from "./SpeedOSDPlugin";
import { TrackEditorPlugin } from "./TrackEditorPlugin";
import { TrackLoaderPlugin } from "./TrackLoaderPlugin";

export const plugins = [
    AdsLoaderPlugin,
    // AssetsLoader,
    CheckpointsPathPlugin,
    CollisionPlugin,
    DroneUpdatePlugin,
    GameplayPlugin,
    ScreenshotPlugin,
    SimulatorPlugin,
    SpeedOSDPlugin,
    TrackEditorPlugin,
    TrackLoaderPlugin
]
export const trackEditorPlugins = [
    CheckpointsPathPlugin,
    TrackLoaderPlugin,
    TrackEditorPlugin,
    SpeedOSDPlugin,
]

export const gameplayPlugins = [
    DroneUpdatePlugin,
    SimulatorPlugin,
    GameplayPlugin,
    SpeedOSDPlugin,
    CollisionPlugin,
    AdsLoaderPlugin,
    TrackLoaderPlugin,
    CheckpointsPathPlugin
]

export function getPluginsByMode(mode: EngineMode) {
    switch (mode) {
        case "game":
            return gameplayPlugins;
        case "editor":
            return trackEditorPlugins;
        default:
            return plugins;
    }
}