import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tracksApi } from "../../api/tracks";
import { trackEditorState } from "../../state/TrackEditorState";
import "./MapDetails.scss";

export const MapDetails = () => {
  const [name, setName] = useState<string>("");
  const params = useParams();
  const navigate = useNavigate();
  const isCreate = !params.id;

  const saveMap = async () => {
    if (isCreate) {
      let request = await tracksApi.create(trackEditorState.getMapData());
      if (request.data.id) {
        navigate(`/tracks/${request.data.id}/edit`);
      }
    } else {
      let request = await tracksApi.update(
        params.id!,
        trackEditorState.getMapData()
      );
    }
  };

  useEffect(() => {
    trackEditorState.on("track:name:changed", (newName) => {
      setName(newName);
    });
    setName(trackEditorState.getMapData().name);
  }, []);
  return (
    <>
      <div className="map-details-holder">
        <label className="label label-input">
          Track name:
          <input
            className="input"
            type="text"
            value={name}
            onChange={(e) => trackEditorState.setMapName(e.currentTarget.value)}
          />
        </label>
        <button className="transparent-button primary" onClick={saveMap}>
          Save
        </button>
      </div>
    </>
  );
};
