import { CannonJSPlugin, Color4, Engine, ILoadingScreen, Scene, Vector3 } from "@babylonjs/core";
import { AdvancedDynamicTexture } from "@babylonjs/gui";
import * as cannon from "cannon";
import { gameSettings } from "../state/GameSettings";
import { EngineMode } from "../state/GameState";
import { Constants } from "../types/Constants";
import { hideSpinner, showSpinner } from "../util/spiner";
import { getPluginsByMode } from "./plugins";
import { createPlugin, IPlugin } from "./plugins/IPlugin";
import { Checkpoint } from "./scene/obstacles/Checkpoint";
import "./shaders";



export class RenderEngine implements ILoadingScreen {
    private renderCanvas: HTMLCanvasElement;

    private mode!: EngineMode;
    private engine!: Engine;
    private scene!: Scene;
    private plugins: IPlugin[] = [];
    public loadingUIBackgroundColor: string = "#000000";
    public loadingUIText!: string;
    private postprocesInit: boolean = false;

    constructor(private canvasId: string = "render-canvas") {
        this.renderCanvas = (document.getElementById(this.canvasId) as any) as HTMLCanvasElement;
        if (!this.renderCanvas) {
            this.renderCanvas = document.createElement("canvas");
            this.renderCanvas.id = this.canvasId;
        }

    }
    initEngine() {
        this.engine = new Engine(this.renderCanvas, true);
        this.scene = new Scene(this.engine);
        this.scene.collisionsEnabled = true;
        this.scene.clearColor = new Color4(0, 0, 0);

        this.scene.enablePhysics(new Vector3(0, -Constants.gravity, 0), new CannonJSPlugin(true, 10, cannon));
        this.engine.loadingScreen = this;
        window.addEventListener("resize", this.resizeCanvas.bind(this));
        this.resizeCanvas();
        const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI", true, this.scene);
        this.scene.metadata = {
            gui: advancedTexture,
            renderEngine: this
        }


    }
    initPostProcess() {
        if (!this.scene?.activeCamera || this.postprocesInit) {
            return;
        }
        const camera = this.scene.activeCamera;
        // const postProcessNoise = new PostProcess("noise", config.assetsUrl + "/gold-noise", ["time"], null, 1.0, this.scene.activeCamera);
        // // postProcess.bind
        // postProcessNoise.onApply = (effect) => {
        //     effect.setFloat("time", Math.random());
        //     effect.setInt("enabled", 1);
        // };
        var blurWidth = 2.2;
        // var postProcess2 = new BlurPostProcess("Horizontal blur", new Vector2(1.0, 0), blurWidth, 0.25, camera);
        // var postProcess3 = new BlurPostProcess("Vertical blur", new Vector2(0, 1.0), blurWidth, 0.25, camera);
        // const postProcess = new FxaaPostProcess("fxaa", 1.0, camera);
        this.postprocesInit = true;
    }

    private renderFunction() {
        if (this.scene?.activeCamera) {
            if (!this.postprocesInit) {
                this.initPostProcess();
            }

            this.scene.render();
        }
    }

    resizeCanvas() {
        if (!this.engine) return;

        if (this.mode === "editor") {
            this.renderCanvas.width = window.innerWidth;
            this.renderCanvas.height = window.innerHeight;
            this.engine.resize();
            return;
        }
        const aspectRatio = gameSettings.screenAspectRation;

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        this.renderCanvas.width = viewportWidth;
        this.renderCanvas.height = viewportHeight;

        if (aspectRatio !== 0) {
            const viewportAspectRatio = viewportWidth / viewportHeight;
            if (viewportAspectRatio > aspectRatio) {
                this.renderCanvas.height = viewportWidth / aspectRatio;
            } else {
                this.renderCanvas.width = viewportHeight * aspectRatio;
            }
        }
        this.engine.resize();
    }

    start(mode: EngineMode) {
        this.mode = mode;
        this.initEngine();
        this.engine.runRenderLoop(this.renderFunction.bind(this));
        this.renderCanvas.style.zIndex = "100";
        this.renderCanvas.style.visibility = "visible";
        this.resizeCanvas();
        this.plugins = getPluginsByMode(this.mode).map(plugin => createPlugin(plugin, this.scene));
        this.plugins.forEach(plugin => plugin.load());
    }
    stop() {
        this.plugins.forEach(plugin => plugin.dispose());
        this.engine.stopRenderLoop();
        this.renderCanvas.style.zIndex = "-1";
        this.renderCanvas.style.visibility = "hidden";
        this.engine.dispose();
    }
    displayLoadingUI() {
        showSpinner();
    }
    hideLoadingUI() {
        hideSpinner();
    }
    setObstacleHelperVisible(visible: boolean) {
        this.scene.getNodes().forEach(node => {
            if (node instanceof Checkpoint) {
                node.setVisibile(visible);
            }
        });
    }
}


