import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { MenuButton } from "./MenuButton";

type MenuLayoutProps = {
  children?: React.ReactNode;
  menu?: React.ReactNode | React.ReactNode[];
  hideMenu?: boolean;
  showBackButton?: boolean;
  showNavigation?: boolean;
};
export const MenuLayout: React.FC<MenuLayoutProps> = ({
  children,
  menu,
  showBackButton,
  hideMenu = false,
  showNavigation = false,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Container fluid style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          {!hideMenu && (
            <Col xs={12} md={2}>
              {menu instanceof Array &&
                menu.map((item, index) => (
                  <Row className="mx-2 my-1" key={index}>
                    {item}
                  </Row>
                ))}
              {menu && !(menu instanceof Array) && menu}
              {showBackButton && (
                <Row className="mx-2 my-1">
                  <MenuButton text={"< Back"} onClick={() => navigate("/")} />
                </Row>
              )}
            </Col>
          )}
          <Col
            xs={12}
            sm={12}
            md={10}
            style={{ height: "100%", overflowY: "auto" }}
          >
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};
