import { Scene } from "@babylonjs/core";
import { config } from "../../../config";
import { GameNode } from "../GameNode";
import { Obstacle } from "./Obstacle";
import { Arrow } from "./misc/Arrow";
import { Box } from "./misc/Box";
import { MGPCube } from "./multigp/MGPCube";
import { MGPDiveGate } from "./multigp/MGPDiveGate";
import { MGPFlag } from "./multigp/MGPFlag";
import { MGPGate } from "./multigp/MGPGate";
import { MGPGateFlag } from "./multigp/MGPGateFlag";
import { MGPHurdle } from "./multigp/MGPHurdle";
import { MGPLadder } from "./multigp/MGPLadder";
import { MGPOffsetGate } from "./multigp/MGPOffsetGate";
import { MGPTower } from "./multigp/MGPTower";
import { TubeGate } from "./tubes/TubeGate";
export type NodeItem = {
  name: string;
  title: string;
  image: string;
  data: any;
}
const url = config.assetsUrl;
export const gameNodes: NodeItem[] = [
  { name: "arrow", title: "Arrow", image: `${url}/images/obstacles/MGPGate5x5.png`, data: {} },
  { name: "box", title: "Box", image: `${url}/images/obstacles/MGPGate5x5.png`, data: {} },
  { name: "tube", title: "Tube", image: `${url}/images/obstacles/MGPGate5x5.png`, data: {} },
  { name: "MGPCube3x5x5", title: "Cube 5x5", image: `${url}/images/obstacles/MGPGate5x5.png`, data: {} },
  { name: "MGPGate5x5", title: "Gate 5x5", image: `${url}/images/obstacles/MGPGate5x5.png`, data: {} },
  { name: "MGPLadder3x5x5", title: "Ladder 5x5", image: `${url}/images/obstacles/MGPLadder3x5x5.png`, data: {} },
  { name: "MGPGate7x6", title: "Gate 7x6", image: `${url}/images/obstacles/MGPGate7x6.png`, data: {} },
  { name: "MGPDiveGate", title: "Dive Gate", image: `${url}/images/obstacles/MGPDiveGate.png`, data: {} },
  { name: "MGPLadder2x5x5", title: "Double gate 5x5", image: `${url}/images/obstacles/MGPLadder2x5x5.png`, data: {} },
  { name: "MGPToplessLadder3x5x5", title: "Topless ladder 5x5", image: `${url}/images/obstacles/MGPToplessLadder3x5x5.png`, data: {} },
  { name: "MGPToplessLadder2x5x5", title: "Topless double gate 5x5", image: `${url}/images/obstacles/MGPToplessLadder2x5x5.png`, data: {} },
  { name: "MGPLadder3x7x6", title: "Ladder 7x6", image: `${url}/images/obstacles/MGPLadder3x7x6.png`, data: {} },
  { name: "MGPLadder2x7x6", title: "Double gate 7x6", image: `${url}/images/obstacles/MGPLadder2x7x6.png`, data: {} },
  { name: "MGPToplessLadder3x7x6", title: "Topless ladder 7x6", image: `${url}/images/obstacles/MGPToplessLadder3x7x6.png`, data: {} },
  { name: "MGPToplessLadder2x7x6", title: "Topless double gate 7x6", image: `${url}/images/obstacles/MGPToplessLadder2x7x6.png`, data: {} },
  { name: "MGPTower5x5", title: "Tower 5x5", image: `${url}/images/obstacles/MGPTower5x5.png`, data: {} },
  { name: "MGPTower7x6", title: "Tower 7x6", image: `${url}/images/obstacles/MGPTower7x6.png`, data: {} },
  { name: "MGPFlagBig", title: "Flag Big", image: `${url}/images/obstacles/MGPFlagBig.png`, data: {} },
  { name: "MGPFlagSmall", title: "Flag Small", image: `${url}/images/obstacles/MGPFlagSmall.png`, data: {} },
  { name: "MGPOffsetGate5x5", title: "Offset Gate 5x5", image: `${url}/images/obstacles/MGPOffsetGate5x5.png`, data: {} },
  { name: "MGPOffsetGate7x6", title: "Offset Gate 7x6", image: `${url}/images/obstacles/MGPOffsetGate7x6.png`, data: {} },
  { name: "MGPHurdle", title: "Hurdle", image: `${url}/images/obstacles/MGPHurdle.png`, data: {} },
  { name: "MGPSuperHurdle", title: "Super Hurdle", image: `${url}/images/obstacles/MGPSuperHurdle.png`, data: {} },
  { name: "MGPGateFlag5x5", title: "Gate Flag 5x5", image: `${url}/images/obstacles/MGPGateFlag5x5.png`, data: {} },
  { name: "MGPGateFlag7x6", title: "Gate Flag 7x6", image: `${url}/images/obstacles/MGPGateFlag7x6.png`, data: {} },
]
export const getObstacleTitle = (name: string) => {
  const obstacle = gameNodes.find(o => o.name === name);
  return obstacle ? obstacle.title : "";
}
export class ObstacleFactory {
  constructor(private scene: Scene) { }

  create(name: string, data: any): Obstacle | GameNode {
    let obstacle;
    switch (name) {
      case "arrow": obstacle = new Arrow(name, this.scene); break;
      case "box": obstacle = new Box(name, this.scene); break;
      case "tube": obstacle = new TubeGate(name, this.scene); break;
      case "MGPCube1x5x5": obstacle = new MGPCube(name, this.scene, false, 1); break;
      case "MGPCube2x5x5": obstacle = new MGPCube(name, this.scene, false, 2); break;
      case "MGPCube3x5x5": obstacle = new MGPCube(name, this.scene, false, 3); break;
      case "MGPCube1x7x6": obstacle = new MGPCube(name, this.scene, true, 1); break;
      case "MGPCube2x7x6": obstacle = new MGPCube(name, this.scene, true, 2); break;
      case "MGPCube3x7x6": obstacle = new MGPCube(name, this.scene, true, 3); break;
      case "MGPGate5x5": obstacle = new MGPGate(name, this.scene, false, false, false); break;
      case "MGPGate7x6": obstacle = new MGPGate(name, this.scene, true, false, false); break;
      case "MGPDiveGate": obstacle = new MGPDiveGate("", this.scene); break;
      case "MGPLadder3x5x5": obstacle = new MGPLadder(name, this.scene, false, false, 3); break;
      case "MGPLadder2x5x5": obstacle = new MGPLadder(name, this.scene, false, false, 2); break;
      case "MGPToplessLadder3x5x5": obstacle = new MGPLadder(name, this.scene, false, true, 3); break;
      case "MGPToplessLadder2x5x5": obstacle = new MGPLadder(name, this.scene, false, true, 2); break;
      case "MGPLadder3x7x6": obstacle = new MGPLadder(name, this.scene, true, false, 3); break;
      case "MGPLadder2x7x6": obstacle = new MGPLadder(name, this.scene, true, false, 2); break;
      case "MGPToplessLadder3x7x6": obstacle = new MGPLadder(name, this.scene, true, true, 3); break;
      case "MGPToplessLadder2x7x6": obstacle = new MGPLadder(name, this.scene, true, true, 2); break;
      case "MGPTower5x5": obstacle = new MGPTower(name, this.scene); break;
      case "MGPTower7x6": obstacle = new MGPTower(name, this.scene, true); break;
      case "MGPFlagBig": obstacle = new MGPFlag(name, this.scene, true); break;
      case "MGPFlagSmall": obstacle = new MGPFlag(name, this.scene, false); break;
      case "MGPOffsetGate5x5": obstacle = new MGPOffsetGate(name, this.scene, false); break;
      case "MGPOffsetGate7x6": obstacle = new MGPOffsetGate(name, this.scene, true); break;
      case "MGPHurdle": obstacle = new MGPHurdle(name, this.scene, false); break;
      case "MGPSuperHurdle": obstacle = new MGPHurdle(name, this.scene, true); break;
      case "MGPGateFlag5x5": obstacle = new MGPGateFlag(name, this.scene, false); break;
      case "MGPGateFlag7x6": obstacle = new MGPGateFlag(name, this.scene, true); break;
      case "TubeGate": obstacle = new TubeGate(name, this.scene); break;

      default:
        throw new Error("Unknown obstacle type: " + data.type);
    }
    return obstacle;
  }

}
