import { Classes, uz } from "unitz-ts";
Classes.addDefaults();

export const GatesConstants = {
    DEPTH: 0.05,
    small: {
        height: uz(`6 ft`).convert("m").value,
        width: uz(`7 ft`).convert("m").value,
        sidePanelSize: uz(`1 ft`).convert("m").value,
        topPanelSize: uz(`1 ft`).convert("m").value,
        towerElevation: uz(`5 ft`).convert("m").value,
        hurdleHeight: uz(`5 ft`).convert("m").value,
        hurdleWidth: uz(`10 ft`).convert("m").value,
    },
    big: {
        height: uz(`8 ft`).convert("m").value,
        width: uz(`10 ft`).convert("m").value,
        sidePanelSize: uz(`1.5 ft`).convert("m").value,
        topPanelSize: uz(`2 ft`).convert("m").value,
        towerElevation: uz(`6 ft`).convert("m").value,
        hurdleHeight: uz(`10 ft`).convert("m").value,
        hurdleWidth: uz(`20 ft`).convert("m").value,
    }
};
export const getGatesSizes = (isBig: boolean) => {
    const {
        height,
        width,
        sidePanelSize,
        topPanelSize,
        towerElevation,
        hurdleHeight,
        hurdleWidth,
    } = isBig ? GatesConstants.big : GatesConstants.small;
    return {
        depth: GatesConstants.DEPTH,
        height,
        width,
        sidePanelSize,
        topPanelSize,
        towerElevation,
        hurdleHeight,
        hurdleWidth,
        innerWidth: width - sidePanelSize * 2,
        sideHeight: height - topPanelSize,
        innerHeight: height - topPanelSize,
    }
}

