
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
    exp: number; // Expiration time (as a UNIX timestamp)
    sub: string;
    name: string;
    image: string;
    iat: number;
}

export type User = {
    uid: string;
    displayName: string;
    photoURL: string;
}

class Auth {
    user: User | null = null;

    checkAuth() {
        const urlParams = new URLSearchParams(window.location.search);
        const queryToken = urlParams.get("token");
        let localToken = localStorage.getItem("token");
        if (queryToken) {
            localStorage.setItem("token", queryToken);
            window.history.replaceState({}, document.title, "/");
            localToken = queryToken;
        }

        if (localToken) {
            const decoded = jwtDecode<JwtPayload>(localToken);

            if (Date.now() <= decoded.exp * 1000) {
                axios.defaults.headers.common["Authorization"] = `${localToken}`;
                this.user = {
                    uid: decoded.sub,
                    displayName: decoded.name,
                    photoURL: decoded.image
                };
            }
        }
    }


}

export const auth = new Auth();