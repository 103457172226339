import {
	Camera,
	FreeCamera,
	LensRenderingPipeline,
	Mesh,
	MeshBuilder,
	PhysicsImpostor,
	Scene,
	Space,
	Tools,
	TransformNode,
	Vector3
} from "@babylonjs/core";
import { gameSettings } from "../../state/GameSettings";
import { ControllerOutput } from "../../types";
import { DroneData } from "../../types/drone";
import { Battery } from "./Battery";
import { DroneDataWrapper } from "./DroneDataWrapper";
import { MotorInterpolator } from "./MotorInterpolator";
import { MotorMixingTable } from "./MotorMixingTable";
import { FC_PIDController } from "./PidController";
export class Drone extends TransformNode {

	public mesh: Mesh;
	public camera: FreeCamera;
	public cameraRoot: TransformNode;
	public droneDataWrapper: DroneDataWrapper;
	public pidController: FC_PIDController;
	public battery: Battery;
	public motorMixingTable: MotorMixingTable;
	public motorInterpolator: MotorInterpolator;

	constructor(
		private scene: Scene,
		private droneData: DroneData
	) {
		super("player", scene);

		this.pidController = new FC_PIDController();
		this.droneDataWrapper = new DroneDataWrapper(this.droneData);


		this.battery = new Battery(1600, 11.1);
		this.motorMixingTable = new MotorMixingTable();
		this.motorInterpolator = new MotorInterpolator([
			{ input: 0, thrust: 37, amps: 1.15, volts: 16.12, rpm: 5726 },
			{ input: 25, thrust: 149, amps: 2.86, volts: 16.08, rpm: 10937 },
			{ input: 50, thrust: 468, amps: 8.77, volts: 15.95, rpm: 18902 },
			{ input: 75, thrust: 893, amps: 19.26, volts: 15.7, rpm: 26262 },
			{ input: 100, thrust: 1370, amps: 34.7, volts: 15.33, rpm: 32660 },

		]);
		if (scene.activeCamera) {
			scene.activeCamera.dispose();
		}
		this.camera = new FreeCamera(`droneCamera`, Vector3.Zero(), scene);
		this.cameraRoot = new TransformNode("cameraRoot");

		this.mesh = MeshBuilder.CreateBox(`drone`, {
			height: this.droneData.frame.height,
			width: this.droneData.frame.width,
			depth: this.droneData.frame.depth,
		}, this.scene);

		this.loadDronData(this.droneData);
	}
	public loadDronData(dd: DroneData) {
		// this.droneData = dd;
		//Camera setup
		this.cameraRoot.position.z = dd.frame.depth / 2;
		this.camera.position.y = 0.01;

		this.cameraRoot.rotate(new Vector3(1, 0, 0), -0.349, Space.LOCAL);
		// this.camera.fov = 0.2;
		this.camera.minZ = 0.001;
		this.camera.maxZ = 1000;

		//Reset camera
		this.camera.parent = null;
		this.cameraRoot.parent = null;
		// simulator.drone.setDroneData(dd);


		console.log("Drone", this.droneData);
		this.initPhysics();

		this.camera.parent = this.cameraRoot;
		this.cameraRoot.parent = this.mesh;
		this.mesh.position.y = dd.frame.height * 2;
		this.mesh.parent = this;
		this.setCameraAngle(15);
		this.calculateFov();
		// this.position.y = 10;
		// this.position.z = 100;
		// this.mesh.physicsImpostor.registerOnPhysicsCollide()
		// this.mesh.showBoundingBox = true;
		// this.mesh.rotate(new Vector3(0, 1, 0), degToRad(180), Space.LOCAL);

	}
	public setCameraAspectRatio(aspectRatio: number) {
		// this.camera.fovMode = 1;
		// this.camera.fov = Tools.ToRadians(90);
	}
	// public setCameraFov(fov: number) {
	// 	// const canvas = this.scene.getEngine().getRenderingCanvas();
	// 	// var aspectRatio = canvas.width / canvas.height;
	// 	// var verticalFOV = Math.atan(Math.tan(fov * 0.5) / aspectRatio);
	// 	// this.camera.fov = Tools.ToRadians(fov);
	// 	this.cameraFov = fov;
	// 	this.caclulateFov();
	// }
	public calculateFov() {
		const fovType = gameSettings.cameraFovType;
		const fov = gameSettings.cameraFov ?? 110;
		let aspectRatio = gameSettings.screenAspectRation;

		if (aspectRatio === 0) {
			const canvas = this.scene.getEngine().getRenderingCanvas();
			if (canvas) {
				aspectRatio = canvas.width / canvas.height;
			}
		}

		if (fovType === "horizontal") {
			this.camera.fovMode = Camera.FOVMODE_HORIZONTAL_FIXED
			this.camera.fov = Tools.ToRadians(fov);
		} else if (fovType === "vertical") {
			this.camera.fovMode = Camera.FOVMODE_VERTICAL_FIXED
			this.camera.fov = Tools.ToRadians(fov);
		} else if (fovType === "diagonal") {
			const dfov = Tools.ToRadians(fov);
			const vfov = 2 * Math.atan((1 / Math.sqrt(aspectRatio * aspectRatio + 1)) * Math.tan(dfov / 2));
			const hfov = 2 * Math.atan(aspectRatio * Math.tan(vfov / 2));
			this.camera.fovMode = Camera.FOVMODE_VERTICAL_FIXED
			this.camera.fov = hfov;
		}

		// const pipeline = new DefaultRenderingPipeline("pipeline", true, this.scene, [this.camera]);

		// pipeline.depthOfFieldEnabled = true;
		// pipeline.depthOfField.focalLength = 2.8;
		// pipeline.depthOfField.fStop = 1.4;
		// pipeline.depthOfField.focusDistance = 4;



		const lensEffect = new LensRenderingPipeline('lensEffects', {
			// edge_blur: 0.2,
			distortion: 1
			//      chromatic_aberration: number;       // from 0 to x (1 for realism)
			//      edge_blur: number;                  // from 0 to x (1 for realism)
			//      distortion: number;                 // from 0 to x (1 for realism), note that this will effect the pointer position precision
			//      grain_amount: number;               // from 0 to 1
			//      grain_texture: BABYLON.Texture;     // texture to use for grain effect; if unset, use random B&W noise
			//      dof_focus_distance: number;         // depth-of-field: focus distance; unset to disable (disabled by default)
			//      dof_aperture: number;               // depth-of-field: focus blur bias (default: 1)
			//      dof_darken: number;                 // depth-of-field: darken that which is out of focus (from 0 to 1, disabled by default)
			//      dof_pentagon: boolean;              // depth-of-field: makes a pentagon-like "bokeh" effect
			//      dof_gain: number;                   // depth-of-field: highlights gain; unset to disable (disabled by default)
			//      dof_threshold: number;              // depth-of-field: highlights threshold (default: 1)
			//      blur_noise: boolean;                // add a little bit of noise to the blur (default: true)

		}, this.scene, 1.0, [this.camera]);
	}
	public setCameraAngle(angle: number) {
		this.cameraRoot.rotation = new Vector3(-Tools.ToRadians(angle), 0, 0);
	}
	public getVelocity() {
		return this.mesh.physicsImpostor?.getLinearVelocity()
	}
	initPhysics() {
		console.log("Drone weight", this.droneDataWrapper.weight);
		this.mesh.physicsImpostor = new PhysicsImpostor(
			this.mesh,
			PhysicsImpostor.BoxImpostor,
			{
				mass: (this.droneDataWrapper.weight + 400) / 1000,
				// mass: 0.6,
				friction: 1,
				restitution: 0.5,

			},
			this.scene
		);
	}
	getRotationRates(): ControllerOutput {
		// Get the drone's angular velocity in radians per second
		const angularVelocity = this.mesh.physicsImpostor?.getAngularVelocity();
		// const angularVelocity = Vector3.Zero();
		// this.mesh.physicsImpostor.getAngularVelocityToRef(angularVelocity);
		// Convert to degrees per second and return
		if (!angularVelocity) {
			return {
				yaw: 0,
				pitch: 0,
				roll: 0,
				throttle: 0,
			};
		}
		return {
			yaw: Tools.ToDegrees(angularVelocity.y),
			pitch: Tools.ToDegrees(angularVelocity.x),
			roll: Tools.ToDegrees(angularVelocity.z),
			throttle: 0,
		};
	}
}
